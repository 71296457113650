<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>步骤号</b></label><br />
            <el-input-number v-model="model.STEP_IDX" :disabled="true"/>
          </div>
          <div class="form-group">
            <label><b>步骤名称</b></label><br />
            <el-input v-model="model.STEP_NAME" :disabled="true"/>
          </div>
          <div class="form-group">
            <label><b>表达式</b></label><br />
            <el-input v-model="model.COND_EXP" :disabled="true"/>
          </div>
          <div class="form-group">
            <label><b>条件值</b></label><br />
            <el-input v-model="model.COND_VAL" />
          </div>
          <div class="form-group">
            <label><b>处理人员</b></label><br />
            <el-select v-model="model.TEACHERS" placeholder="请选择" multiple style="width: 80%">
              <el-option
                  v-for="item in tea.ls"
                  :key="item.ID"
                  :label="item.NAME"
                  :value="item.ID">
              </el-option>
            </el-select>
          </div>
<!--          <div class="form-group">-->
<!--            <label><b>排序</b></label><br />-->
<!--            <el-input-number v-model="model.SORT" :min="1"/>-->
<!--          </div>-->
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{show:false,loading:false},
      model: {TEACHERS:[]},
      tea:{
        ls:[],
      }
    }
  },
  methods:{
    init(model){
      this.sta={show:true,loading:false}
      this.model=Object.assign({},model,{TEACHERS:[]});
      this.model.TEACHERS=this.model.TEA_IDS.split(',').map(o=>parseInt(o));
      let self=this;
      if (this.tea.ls.length==0){
        this.whale.remote.getCollection({
          url:"/api/School/ORG/TeacherApi/GetList",
          completed(its){
            self.tea.ls=its;
          }
        })
      }
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.model.TEA_IDS=this.model.TEACHERS.join(',');
      let arr=[];
      for(let i=0;i<this.tea.ls.length;i++){
        let it=this.tea.ls[i];
        if (this.model.TEACHERS.indexOf(it.ID)>=0){
          arr.push(it.NAME);
        }
      }
      this.model.TEA_NAME=arr.join(',');
      this.whale.remote.getResult({
        url:"/api/School/MAT/MVConfApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
